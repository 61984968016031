import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshopNoTab,
  InternalInstructor,
  InternalTestimonial
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './florais-de-bach-para-criancas.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Florais de Bach para Crianças' />
      <InternalHeader className={styles.header} withType>
        <small>Online</small>
        <h1>
          Florais de Bach
          <br />
          <strong>para Crianças</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Curso</strong>
            </h2>
            <p>
              Um curso de vanguarda na área das terapias integrativas, onde todo o sistema dos Florais de Bach é
              apresentado através da linguagem da alma infantil, cada criança é única, sua fórmula floral deve ser
              preparada de forma personalizada e direcionada especificamente para suas necessidades. Numa ótica
              inovadora, vamos trilhar o caminho feito pelo Dr Bach na descoberta das 38 essências florais, primeiro,
              estudando os 12 Curadores e as virtudes a serem aprendidas pela alma da criança, com os 07 Auxiliares
              estudamos os bloqueios criados através dos comportamentos aprendidos no ambiente familiar e por fim, com
              os 19 Complementares trabalhamos no desenvolvimento das habilidades necessárias para enfrentar os desafios
              que a vida traz para criança.
            </p>
            <h2>Objetivo</h2>
            <p>
              O objetivo do curso é formar o terapeuta floral materno infantil com um olhar voltado para a criança como
              um todo, seu ambiente, histórico social, crenças e padrões de comportamento aprendidos. O olhar desse
              terapeuta está além do sintoma, está na compreensão da criança dentro do seu contexto emocional e de
              desenvolvimento. Um profissional capacitado a identificar as emoções em desarmonia e buscar nas essências
              florais as virtudes que irão contribuir no desenvolvimento infantil concebendo uma fórmula específica e
              assertiva para o processo individual de cada criança.
            </p>
            <h2>
              O que você <strong>vai aprender</strong>
            </h2>
            <h3>Conceito dos Florais de Bach</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A Filosofia e Obra do Dr Edward Bach
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Ação das essências florais no organismo para trazer o equilíbrio emocional
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Uso e aplicações dos florais de Bach
              </dt>
            </dl>
            <br />
            <br />
            <h3>A criança e seus estados emocionais</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Os 12 Curadores as lições da alma infantil
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                07 Auxiliares e os comportamentos crônicos aprendidos no ambiente familiar
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                19 Complementares e os desafios que a vida nos traz
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Estudo das 38 essências dos Florais de Bach e seus estados emocionais
              </dt>
            </dl>
            <br />
            <br />
            <h3>O Universo materno infantil</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Metodologia de apoio aos pais
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Parentalidade Consciente
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Educação positiva
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A orientação aos pais combinada com a terapia
                floral
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                As fases do desenvolvimento infantil
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Anamnese infantil e condução com pais
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Fórmulas personalizadas e assertivas
              </dt>
            </dl>
            <br />
            <br />
            <h3>Aulas gravadas com mentorias mensais ao vivo</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Sempre na 2º terça-feira do mês temos um encontro ao vivo para tirar dívidas e discussão dos casos
                clínicos
              </dt>
            </dl>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshopNoTab>
              <h3>
                <small>Curso Online</small>
                Florais de Bach <br />
                para Crianças
              </h3>
              <ul>
                <li>Aulas online por plataforma digital de fácil interação</li>
                <li>Você assiste quando quiser</li>
                <li>Disponíveis imediatamente</li>
                <li>Tire suas dúvidas durante as mentorias mensais ao vivo</li>
                <li>Material em pdf incluso</li>
                <li>Certificado de conclusão </li>
                <li>Certificado de supervisão e prática clínica </li>
                <li>Sem Pré-requisito </li>
              </ul>
              <a
                href={WHATSAPPLINK}
                target='_blank'
                rel='noopener noreferrer'
                className={`button button--purpleReverse button internalWorkshop-button`}
              >
                Inscrição Curso Online <small></small>
              </a>
            </InternalWorkshopNoTab>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
